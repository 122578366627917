import React from 'react';

import ProfileLink, {ProfileLinkTarget} from '../ProfileLink';

import photo from 'url:../../assets/profile-photo.jpg?width=1024';
import resume from 'url:../../assets/resume.pdf';

export default function Profile() {
  return (
    <div className="flex flex-col self-center text-center px-8 w-full">
      <img
        src={photo}
        className="rounded-full sm:w-1/2 max-w-md self-center my-8 lg:my-4"
        alt="Face photo of Alex Kennedy"
      />
      <h1 className="text-6xl lg:text-4xl font-bold py-8 lg:p-4 text-slate-800">
        Alex Kennedy
      </h1>
      <h2 className="text-5xl lg:text-3xl p-8 lg:p-4 text-slate-600 wrap">
        Site Reliability Engineer at Google
      </h2>

      <div className="flex flex-col py-8 lg:py-4 w-full lg:max-w-md self-center">
        <ProfileLink
          target={ProfileLinkTarget.LinkedIn}
          href="https://www.linkedin.com/in/alex-kennedy/"
          label="LinkedIn"
        />
        <ProfileLink
          target={ProfileLinkTarget.Resume}
          label="Resume"
          href={resume}
        />
        <ProfileLink
          target={ProfileLinkTarget.GitHub}
          href="https://github.com/alex-kennedy"
          label="GitHub"
        />
        <ProfileLink
          target={ProfileLinkTarget.Orcid}
          href="https://orcid.org/0000-0003-2787-8780"
          label="ORCID iD"
        />
      </div>
    </div>
  );
}
