import React from 'react';
import {Helmet} from 'react-helmet';

import Header from '../Header';
import Footer from '../Footer';
import Profile from '../Profile';

export function App() {
  return (
    <>
      <Helmet>
        <title>Alex Kennedy</title>
        <meta name="description" content="Alex Kennedy online resume" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />

        {/* Favicons */}
        <link rel="icon" href="/images/favicon.svg" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
        <link rel="mask-icon" href="/images/apple-touch-icon.png" />
      </Helmet>
      <div className='min-h-full flex flex-col justify-between'>
        <Header />
        <Profile />
        <Footer />
      </div>
    </>
  );
}
