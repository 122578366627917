import React from 'react';

export default function Header() {
  const linkClasses = 'text-4xl lg:text-2xl ml-2';
  return (
    <header>
      <nav className="flex w-screen lg:max-w-screen-md mx-auto p-8 justify-between">
        <div className="logo">
          <a
            href="/"
            accessKey="h"
            title="alexkennedy.dev (Alt + H)"
            className="text-5xl lg:text-3xl font-bold text-slate-800"
          >
            Alex Kennedy
          </a>
        </div>
        <ul className='flex flex-row space-x-4 self-center'>
          <li>
            <a href="/" title="Home" className={linkClasses}>
              Home
            </a>
          </li>
          {/* <li>
            <a href="/projects" title="Projects" className={linkClasses}>
              Projects
            </a>
          </li> */}
        </ul>
      </nav>
    </header>
  );
}
