import React from 'react';

import GitHubIcon from 'jsx:../../assets/icons/github.svg';
import LinkedInIcon from 'jsx:../../assets/icons/linkedin.svg';
import LinkIcon from 'jsx:../../assets/icons/link.svg';
import OrcidIcon from 'jsx:../../assets/icons/orcid.svg';

interface ProfileLinkProps {
  target: ProfileLinkTarget;
  href: string;
  label: string;
}

export enum ProfileLinkTarget {
  GitHub = 1,
  LinkedIn,
  Resume,
  Orcid,
  Email,
}

type IconType = GitHubIcon | LinkedInIcon | LinkIcon | OrcidIcon;

interface LinkStyle {
  icon: IconType;
  background: string;
}

function getLinkStyle(target: ProfileLinkTarget): LinkStyle {
  switch (target) {
    case ProfileLinkTarget.GitHub: {
      return {icon: GitHubIcon, background: '#15191F'};
    }
    case ProfileLinkTarget.LinkedIn: {
      return {icon: LinkedInIcon, background: '#0077B5'};
    }
    case ProfileLinkTarget.Resume: {
      return {
        icon: LinkIcon,
        background: 'linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)'
      };
    }
    case ProfileLinkTarget.Orcid: {
      return {icon: OrcidIcon, background: '#9AC934'};
    }
    default: {
      // Should never happen (famous last words)
      return {icon: LinkIcon, background: '#000'};
    }
  }
}

export default function ProfileLink(props: ProfileLinkProps) {
  const linkStyle = getLinkStyle(props.target);
  return (
    <a href={props.href} target="_blank">
      <div
        className="flex w-full rounded-full p-6 my-2 lg:p-2 justify-center text-white hover:brightness-90 size text-5xl lg:text-2xl"
        style={{background: linkStyle.background}}
      >
        <linkStyle.icon className="text-white mr-4 h-[48] lg:h-[32] lg:mr-2" />
        {props.label}
      </div>
    </a>
  );
}
