import React from 'react';

export default function Footer() {
  return (
    <footer className="p-8 text-center">
      <a href="/" title="alexkennedy.dev" className="text-gray-500 text-2xl">
        <span>alexkennedy.dev | 🇳🇿</span>
      </a>
    </footer>
  );
}
